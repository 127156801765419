/* eslint-disable quotes */
import store from "@/store";
import { ENTITIES_STORE_MODULE_NAME } from "@/views/apps/entities/constants/entities-store-constants";
import entitiesStoreModule from "@/views/apps/entities/store/entitiesStoreModule";
import { AppIDLUT } from "@copernicsw/community-common";
import { PEOPLE_STORE_MODULE_NAME } from "@/views/apps/people/constants/people-store-constants";
import peopleStoreModule from "@/views/apps/people/store/peopleStoreModule";
import { translateTranslationTable } from "@/@core/libs/i18n/utils";
import { ENABLED_APPS_GETTERS } from "@/store/enabled-apps/enabled-apps-store-constants";

const { locale } = store.state;

export default async function fetchAppsInMenu() {
  const appsInMenu = [];
  const { apps } = store.getters;
  const { currentLocale } = locale;

  let response;
  const [, communitySlug] = window.location.pathname.split("/");
  if (apps.apps == null) {
    response = apps;
  } else {
    response = apps;
  }
  const activatedApps = response.apps != null
    ? Object.values(response.apps).filter((app) => app.isInMenu === true)
    : [];

  activatedApps.map(async (app) => {
    if (app.key === "entities") {
      if (!store.hasModule(ENTITIES_STORE_MODULE_NAME)) {
        store.registerModule(ENTITIES_STORE_MODULE_NAME, entitiesStoreModule);
      }
      let types = [];

      if (
        app.customization?.displayOptions === 1
        || app.customization?.displayOptions === 3
      ) {
        types.push({
          name:
            translateTranslationTable(currentLocale, app.customizationName)
            || translateTranslationTable(currentLocale, app.name),
          key: "noKey",
          namePlural:
            translateTranslationTable(currentLocale, app.customizationName)
            || translateTranslationTable(currentLocale, app.name),
          id: 57,
          customization: app.customization,
        });
      }

      if (
        app.customization?.displayOptions === 2
        || app.customization?.displayOptions === 3
      ) {
        if (store.getters.types?.entities?.unpaginated == null) {
          await store.dispatch("getItems", {
            itemType: "types",
            storedKey: "entities",
            page: 1,
            perPage: 200,
            requestConfig: {
              getCommunityTypes: 1,
              modelType: "entity",
              communitySlug,
            },
          });
        }
        types = store.getters.types?.entities?.unpaginated || [];
      }

      for (const row of types) {
        let name = app.customization?.[row.key];

        if (name?.customizationName) {
          name = name.customizationName;
        }

        if (typeof name === "object" && Object.values(name)[0] == null) {
          name = null;
        }

        if (!name) {
          name = row.namePlural;
        }
        if (
          appsInMenu.find(
            (x) => x.title === translateTranslationTable(currentLocale, name),
          ) != null
        ) {
          continue;
        }
        appsInMenu.push({
          title: translateTranslationTable(currentLocale, name),
          route: { name: "entity", params: { typeKey: row.key } },
          icon: row.iconKey,
          id: 57,
          customization: app.customization,
        });
      }
    } else if (app.key === "people") {
      if (!store.hasModule(PEOPLE_STORE_MODULE_NAME)) {
        store.registerModule(PEOPLE_STORE_MODULE_NAME, peopleStoreModule);
      }

      let types = [];

      if (
        app.customization?.displayOptions === 1
        || app.customization?.displayOptions === 3
      ) {
        types.push({
          name:
            translateTranslationTable(currentLocale, app.customizationName)
            || translateTranslationTable(currentLocale, app.name),
          // name: app.name,
          key: "noKey",
          // namePlural: app.name,
          namePlural:
            translateTranslationTable(currentLocale, app.customizationName)
            || translateTranslationTable(currentLocale, app.name),
          id: 35,
          customization: app.customization,
        });
      }

      if (
        app.customization?.displayOptions === 2
        || app.customization?.displayOptions === 3
      ) {
        if (store.getters.types?.people?.unpaginated == null) {
          await store.dispatch("getItems", {
            itemType: "types",
            storedKey: "people",
            page: 1,
            perPage: 200,
            requestConfig: {
              getCommunityTypes: 1,
              modelType: "people",
              communitySlug,
            },
          });
        }

        types = store.getters.types?.people?.unpaginated || [];
      }

      for (const row of types) {
        let name = app.customization?.[row.key];

        if (name?.customizationName) {
          name = name.customizationName;
        }

        if (!name) {
          name = row.namePlural;
        }
        if (
          appsInMenu.find(
            (x) => x.title === translateTranslationTable(currentLocale, name),
          ) != null
        ) {
          continue;
        }
        appsInMenu.push({
          title: translateTranslationTable(currentLocale, name),
          route: { name: "people", params: { typeKey: row.key } },
          icon: row.iconClass,
        });
      }
    } else if (app.key === "live-streaming") {
      appsInMenu.push({
        title:
          translateTranslationTable(currentLocale, app.customizationName)
          || app.name,
        route: "streaming",
        icon: app.iconKey,
        id: 45,
        customization: app.customization,
      });
    } else if (app.key === "events") {
      const types = [];
      if (app?.customization?.displayOptions === 1) {
        types.push({
          name: "present",
          key: 1,
          id: 96,
          namePlural: { ca: "Actuals", es: "Actuales", en: "Present" },
          customizations: app?.customization?.[1],
        });
        types.push({
          name: "futur",
          key: 3,
          id: 96,
          namePlural: { ca: "Propers", es: "Próximos", en: "Upcoming" },
          customizations: app?.customization?.displayOptions[3],
        });
        types.push({
          name: "past",
          key: 4,
          id: 96,
          namePlural: { ca: "Passats", es: "Pasados", en: "Past" },
          customizations: app?.customization?.displayOptions[4],
        });
      } else if (app?.customization?.displayOptionsdisplayOptions === 2) {
        types.push({
          name: "present-futur",
          key: 2,
          id: 96,
          namePlural: {
            ca: "Actuals i propers",
            es: "Actuales i próximos",
            en: "Present and upcoming",
          },
          customizations: app?.customization?.displayOptions[2],
        });
        types.push({
          name: "past",
          key: 4,
          id: 96,
          namePlural: { ca: "Passats", es: "Pasados", en: "Past" },
          customizations: app?.customization?.displayOptions[4],
        });
      } else {
        types.push({
          name: "events",
          key: 0,
          id: 96,
          namePlural: { ca: "Esdeveniments", es: "Eventos", en: "Events" },
        });
      }
      types.forEach((type) => {
        appsInMenu.push({
          title: computedTitle(type),
          route: { name: "events", query: { display: type.key } },
          icon: type.iconClass,
        });
      });
    } else {
      // TODO: title in platform language
      appsInMenu.push({
        title: app.customizationName || app.name,
        route: app.key,
        icon: app.iconKey,
        id: app.id,
        customization: app.customization,
      });
    }
  });

  const dashboardObj = { 
    'en': 'Dashboard', 
    'es': 'Panel de control', 
    'ca': 'Panell de control'
  };
  const homeObj = { 
    'en': 'Home', 
    'es': 'Inicio', 
    'ca': 'Inici'
  };
  const firstTabTitle = store.getters.currentCollective.mainType === 'Workspace' ? dashboardObj : homeObj;
  // console.log('firstTabTitle', firstTabTitle);
  // const firstTabTitleTranslated = translateTranslationTable(currentLocale, firstTabTitle);
  // console.log('firstTabTitleTranslated', firstTabTitleTranslated);

  appsInMenu.unshift({
    title: firstTabTitle,
    route: "Resumen",
    icon: "HomeIcon",
  });
  return appsInMenu;
}
function storedApp(appId) {
  const appNameKey = AppIDLUT[appId];
  return store.getters[ENABLED_APPS_GETTERS.enabledApps][appNameKey];
}
function computedAppNames(type) {
  const app = storedApp(type.id);

  if (type.key) {
    return app?.customization?.[type.key];
  }
  return app?.customizationName;
}
function computedTitle(type) {
  const { currentLocale } = locale;

  const app = storedApp(type.id);
  if (type.key) {
    if (
      app?.customization?.[type.key] != null
      && app?.customization?.[type.key] !== ""
    ) {
      return translateTranslationTable(
        currentLocale,
        app?.customization?.[type.key],
      );
    }
  }

  if (
    !type.key
    && app?.customizationName != null
    && app?.customizationName !== ""
  ) {
    return translateTranslationTable(currentLocale, computedAppNames(type));
  }
  return translateTranslationTable(currentLocale, type.namePlural);
}
